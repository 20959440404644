import { Component } from '@angular/core';
import { BaseListComponent } from '../common/base-list.component';
import { PortList } from '../models/portVM';
import { PortService } from '../services/port.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wpd-port-list',
  templateUrl: './port-list.component.html',
  styleUrls: ['./port-list.component.css']
})
export class PortListComponent extends BaseListComponent<PortList> {
  displayedColumns: string[] = ['id', 'plant', 'name', 'region', 'country', 'products', 'isActive', 'isHallmark', 'isShipyard'];
  fIsActive = new FormControl();
  fIsHallmark = new FormControl();
  fIsShipyard = new FormControl();
  fProductExist = new FormControl();
  
  constructor(service: PortService, router: Router, protected snackBar: MatSnackBar ) {
    super(service, router, snackBar);
  }

  override getPageFilter(): any { 
    return { fIsActive: this.fIsActive.value, fIsHallmark: this.fIsHallmark.value, fIsShipyard: this.fIsShipyard.value, fProductExist: this.fProductExist.value };
  };
}
